import { useState } from "react";
import ChangePasswordUI from "./ui";
import { useParams } from "react-router-dom";
import { openNotification } from "utils/toast.util";
import { changeForgotPassword } from "apis/auth";

export default function ChangePasswordPage() {

    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams();

    const handlePassword = (e: any) => {
        setPassword(() => e.target.value);
    }

    const handleConfirmPassword = (e: any) => {
        setConfirmPassword(() => e.target.value);
    }

    const confirmChangePassword = async (e: any) => {
        e.preventDefault();
        if (!!password && !!confirmPassword) {
            if (password === confirmPassword) {
                const res = await changeForgotPassword(token || '', confirmPassword);
                openNotification(res.msg);
            } else {
                openNotification('Please confirm your password')
            }
        } else {
            openNotification('Please enter your password')
        }
    }


    return (
        <ChangePasswordUI
            handlePassword={handlePassword}
            handleConfirmPassword={handleConfirmPassword}
            confirmChangePassword={confirmChangePassword}
            loading={loading}
        />
    )
}