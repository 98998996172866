import AppSidebar from "./app";
import NewsSidebar from "./news";
import LandingSidebar from "./landing";

const SidebarComponent = {
    AppSidebar,
    NewsSidebar,
    LandingSidebar
}



export default SidebarComponent