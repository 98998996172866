
import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import { P, Span } from "components/basic/text";
import Link from "components/basic/link";
import { Input } from "components/basic/input";
import styled from "styled-components";
import Button from "components/basic/button";
import Constants from "constant";
import Icon from "components/basic/icon";
import configs from "configs";

interface ChangePasswordUIProps {
    handlePassword:any
    handleConfirmPassword:any
    confirmChangePassword:any
    loading:boolean
}

export default function ChangePasswordUI({ handlePassword, handleConfirmPassword, confirmChangePassword, loading }:ChangePasswordUIProps) {
    return (
        <ChangePasswordWrapper>
            <Flex as="form" $style={{
                vAlign: "center",
                fDirection: "column",
                gap: "2rem",
                w: "100%",
                maxW: "25rem"
            }}>
                <Flex $style={{
                    fDirection: "column",
                    vAlign: "center",
                    gap: "2rem",
                    w: "100%"
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        gap: "2rem",
                        vAlign: "center",
                        w: "100%"
                    }}>
                        <Link to={configs.appConfigs.appPath.PUBLIC_PREFIX}>
                            <Icon icon="logo" />
                        </Link>
                        <Heading level={4} $style={{
                            color: "white"
                        }}>Change your Password</Heading>
                        <Flex $style={{
                            fDirection: "column",
                            w: "100%",
                            gap: "1rem"
                        }}>
                            <Flex $style={{
                                fDirection: "column",
                                gap: "1rem",
                                mb: "0.5rem"
                            }}>
                                <Input type="password" onChange={(e:any) => handlePassword(e)} $style={{
                                    bg: "white",
                                    color: "black",
                                    radius: "1rem"
                                }} placeholder="Enter your new password" />
                                <Input type="password" onChange={(e:any) => handleConfirmPassword(e)} $style={{
                                    bg: "white",
                                    color: "black",
                                    radius: "1rem"
                                }} placeholder="Confirm your new password" />
                            </Flex>
                            <Button loading={loading} onClick={(e:any) => confirmChangePassword(e)} $style={{
                                bg: "bg-2",
                                kind: "radius",
                                hover: "bg-hover"
                            }}>Confirm</Button>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex $style={{
                    vAlign: "center",
                    gap: ".5rem"
                }}>
                    <P>
                        Already have account ?
                    </P>
                    <Link to={configs.appConfigs.appPath.SIGNIN_PREFIX} $style={{
                        color: "color-2"
                    }}>
                        Sign in
                    </Link>
                </Flex>
            </Flex>
        </ChangePasswordWrapper>
    )
}

const ChangePasswordWrapper = styled.section`
    height:100vh;
    background-size:cover !important;
    position:relative;
    z-index:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding: 1rem;
    background: url(${Constants.Imgs.BG});
    &:before {
        content:"";
        background-color:rgba(0,0,0,.7);
        width:100%;
        height:100%;
        position:absolute;
        z-index:10;
    }
    form {
        position:relative;
        z-index:15;
    }
`