import configs from "configs";
import HeaderUI from "./ui";
import { useDispatch, useSelector } from "react-redux";
import { actions as appActions } from "store/app.slice";
import { useNavigate } from "react-router-dom";

export default function AppHeader() {

    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.user.userData);
    const navigator = useNavigate();

    const handleClick = () => {
        dispatch(appActions.toggleMenu());
    };

    const handleNewsClick = () => {
        dispatch(appActions.toggleNews())
    }

    const handleAuthRouter = (path: any) => {
        if (userData.payment === undefined) {
            navigator(configs.appConfigs.appPath.SIGNIN_PREFIX);
        } else {
            navigator(path);
        }
    };

    return (
        <HeaderUI
            handleAuthRouter={handleAuthRouter}
            handleMenu={handleClick}
            handleNotify={handleNewsClick}
            signed={userData._id ? true : false}
            userData={userData}
        />
    )
}