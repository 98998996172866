import Flex from "components/basic/flex";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import { Span } from "components/basic/text";
import configs from "configs";
import Constants from "constant";
import MenuItem from "../../item";

interface SidebarProps {
    isOpen: boolean,
    handleClose: any,
    role: number
}

export default function AppSidebarUI({ isOpen, handleClose, role }: SidebarProps) {

    return (
        <Flex as="sidebar" $style={{
            fDirection: "column",
            gap: "3rem",
            minW: "20rem",
            p: "4rem 1rem 1rem 1rem",
            background: "#000000",
            position: "relative",
            queries: {
                1440: {
                    minW: "auto"
                },
                480: !isOpen ? {
                    position: "absolute",
                    w: "0",
                    overflow: "hidden",
                    p: "0"
                } : {
                    position: "fixed",
                    zIndex: "99",
                    h: "100vh",
                    w: "20rem",
                    top: "0",
                    p: "4rem 1rem 1rem 1rem"
                }
            }
        }}>
            <Flex onClick={() => handleClose('close', 'close')} $style={{
                position: 'absolute',
                right: "1rem",
                top: "1rem",
                display: "none",
                queries: {
                    480: {
                        display: "block"
                    }
                }
            }}>
                <Icon icon={'close-white'} />
            </Flex>
            <Flex $style={{
                fDirection: "column",
                gap: "2rem"
            }}>
                <MenuItem 
                    onClick={handleClose} 
                    type={0} 
                    link={configs.appConfigs.appPath.APP_PREFIX} 
                    icon="dashboard" 
                    text="Dashboard" 
                />
                <MenuItem 
                    onClick={handleClose} 
                    type={0}
                    link={configs.appConfigs.appPath.OVERVIEW_PREFIX} 
                    icon="graph-pie" 
                    text="Overview" 
                />
                <MenuItem onClick={handleClose} type={0} link={configs.appConfigs.appPath.PROPOSAL_PREFIX} icon="wallet" text="Governance" />
                <MenuItem onClick={handleClose} type={0} link={configs.appConfigs.appPath.PAY_PREFIX} icon="coin" text="Purchase coin" />
                <MenuItem onClick={handleClose} type={0} link={configs.appConfigs.appPath.PROFILE_PREFIX} icon="user" text="Account" />
                {
                    role === 0 &&
                    <MenuItem onClick={handleClose} type={0} link={configs.appConfigs.appPath.ADMIN_PREFIX} icon="admin" text="Admin" />
                }
            </Flex>
            <Flex $style={{
                fDirection: "column",
                gap: "2rem"
            }}>
                <Span $style={{
                    queries: {
                        1440: {
                            display: "none"
                        },
                        480: !isOpen ? {

                        } : {
                            display: "block"
                        }
                    }
                }}>Property Documents</Span>
                <Flex $style={{
                    fDirection: "column",
                    gap: "1rem"
                }}>
                    <Flex $style={{
                        vAlign: "center",
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            w: ".5rem",
                            h: ".5rem",
                            radius: "100%",
                            background: "#0062FF"
                        }}></Flex>
                        <Flex $style={{
                            gap: "1rem",
                            queries: {
                                1440: {
                                    display: "none"
                                },
                                480: !isOpen ? {

                                } : {
                                    display: "block"
                                }
                            }
                        }}>
                            <Span>Accounting</Span>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        vAlign: "center",
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            w: ".5rem",
                            h: ".5rem",
                            radius: "100%",
                            background: "#FF8743"
                        }}></Flex>
                        <Flex $style={{
                            gap: "1rem",
                            queries: {
                                1440: {
                                    display: "none"
                                },
                                480: !isOpen ? {

                                } : {
                                    display: "block"
                                }
                            }
                        }}>
                            <Span>Income</Span>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        vAlign: "center",
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            w: ".5rem",
                            h: ".5rem",
                            radius: "100%",
                            background: "#4807EA"
                        }}></Flex>
                        <Flex $style={{
                            gap: "1rem",
                            queries: {
                                1440: {
                                    display: "none"
                                },
                                480: !isOpen ? {

                                } : {
                                    display: "block"
                                }
                            }
                        }}>
                            <Span>Expenses</Span>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        vAlign: "center",
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            w: ".5rem",
                            h: ".5rem",
                            radius: "100%",
                            background: "#FD4438"
                        }}></Flex>
                        <Flex $style={{
                            gap: "1rem",
                            queries: {
                                1440: {
                                    display: "none"
                                },
                                480: !isOpen ? {

                                } : {
                                    display: "block"
                                }
                            }
                        }}>
                            <Span>Tax Forms</Span>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        vAlign: "center",
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            w: ".5rem",
                            h: ".5rem",
                            radius: "100%",
                            background: "#FF8743"
                        }}></Flex>
                        <Flex $style={{
                            gap: "1rem",
                            queries: {
                                1440: {
                                    display: "none"
                                },
                                480: !isOpen ? {

                                } : {
                                    display: "block"
                                }
                            }
                        }}>
                            <Span>Financial Audit
                            </Span>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex>
                <MenuItem 
                    onClick={handleClose} 
                    type={1}
                    icon="logout"
                    text="Logout"
                    link={configs.appConfigs.appPath.PUBLIC_PREFIX}
                />
            </Flex>
        </Flex>
    )
}