import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { actions as appActions } from "store/app.slice";
import AppSidebarUI from "./ui";
import { useNavigate } from "react-router-dom";
import configs from "configs";
import { logout } from "apis/auth";

export default function AppSidebar() {

    const menuState = useSelector((state: any) => state.app.menu);
    const [isOpen, setIsOpen] = useState(menuState);
    const userData = useSelector((state: any) => state.user.userData);

    const navigator = useNavigate();


    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(menuState)
    }, [menuState])


    const handleClose = async (path: any, type: number) => {
        dispatch(appActions.toggleMenu());

        if(type === 1) {
            const res = await logout();
            navigator(path);
        }

        else {
            if (path !== "close" ) {
                navigator(path);
            }
        }
    };

    return (
        <AppSidebarUI
            handleClose={handleClose}
            isOpen={isOpen}
            role={userData.role}
        />
    )
}