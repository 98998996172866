import React from "react";
import { StyledButton, StyledButtonPropsType } from "./style";
import Spinner from "../spinner";

interface ButtonPropsType {
    children: any
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    $style?: StyledButtonPropsType
    loading?: boolean
}

const Button: React.FC<ButtonPropsType> = ({
    children,
    $style,
    loading,
    ...rest
}) => {
    return (
        <StyledButton
            {...$style}
            {...rest}
        >
            {children}
            {
                loading && <Spinner/>
            }
        </StyledButton>
    )
}

export default Button;
