import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Icon from "components/basic/icon";
import Link from "components/basic/link";
import { Span } from "components/basic/text";
import configs from "configs";
import { useDispatch } from "react-redux";
import { actions as appActions } from "store/app.slice";
import AppHeader from "./app";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

const HeaderComponent = {
    AppHeader
}

export default HeaderComponent;

export const LandingHeader = () => {

    const dispatch = useDispatch();
    const [loginStatus, setLoginStatus] = useState(false);
    const cookieValue = Cookies.get('token');

    useEffect(() => {
        cookieValue && setLoginStatus(true);
    }, [cookieValue])

    const handleClick = () => {
        dispatch(appActions.toggleLand());
    };

    return (
        <Flex $style={{
            vAlign: "center",
            hAlign: "center",
            w: "100%",
            p: "1rem"
        }}>
            <Flex $style={{
                w: "100%",
                maxW: "1440px",
                vAlign: "center",
                hAlign: "space-between",
            }}>
                <Icon icon={'logo'} />
                <Flex $style={{
                    gap: "2rem",
                    queries: {
                        680: {
                            display: "none"
                        }
                    }
                }}>
                    <a href="#about">
                        <Span level={5}>About</Span>
                    </a>
                    <a href="#pros">
                        <Span level={5}>Pros</Span>
                    </a>
                    <a href="#vision">
                        <Span level={5}>Vision</Span>
                    </a>
                    <a href="#teams">
                        <Span level={5}>Teams</Span>
                    </a>
                </Flex>
                <Flex $style={{
                    display: "none",
                    queries: {
                        680: {
                            display: "block"
                        }
                    }
                }}>
                    <Icon onClick={() => handleClick()} icon='menu' />
                </Flex>
                <Flex $style={{
                    queries: {
                        680: {
                            display: "none"
                        }
                    }
                }}>
                    <Link to={
                        loginStatus ?
                            configs.appConfigs.appPath.APP_PREFIX
                            :
                            configs.appConfigs.appPath.SIGNIN_PREFIX
                    }>
                        <Button $style={{
                            kind: "radius",
                            hover: "bg-2",
                            border: "1px solid #1DAEFF"
                        }}>
                            Go to DAO
                        </Button>
                    </Link>
                </Flex>
            </Flex>
        </Flex>
    )
}