import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Grid from "components/basic/grid";
import Heading from "components/basic/heading";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import Link from "components/basic/link";
import { P, Span } from "components/basic/text";
import Footer from "components/pages/footer";
import { LandingHeader } from "components/pages/header";
import SidebarComponent from "components/pages/sidebar";
import configs from "configs";
import Constants from "constant";
import ParticleImage, { forces, Vector } from "react-particle-image";

const { LandingSidebar } = SidebarComponent;

export default function LandingPage() {
    return (
        <Flex $style={{
            fDirection: "column",
            background: "#030B15"
        }}>
            <LandingHeader />
            <Flex $style={{
                vAlign: "center",
                fDirection: "column",
                p: "1rem"
            }}>
                <Flex data-aos="fade-up" id="home" $style={{
                    w: "100%",
                    maxW: "1440px",
                    mb: "12rem",
                    p: "3rem 1rem"
                }}>
                    <Flex $style={{
                        hAlign: "space-between",
                        w: "100%",
                        vAlign: "center",
                        queries: {
                            920: {
                                fDirection: "column",
                                gap: "2rem"
                            }
                        }
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            maxW: "40rem",
                            queries: {
                                920: {
                                    order: "2"
                                }
                            }
                        }}>
                            <Flex $style={{
                                mb: "2rem",
                                fDirection: "column",
                                queries: {
                                    920: {
                                        vAlign: "center"
                                    }
                                }
                            }}>
                                <Flex $style={{
                                    fDirection: "column",
                                    mb: "1.5rem",
                                    queries: {
                                        920: {
                                            vAlign: "center"
                                        }
                                    }
                                }}>
                                    <Heading level={2}>
                                        Welcome to <Span $style={{
                                            color: "color-2"
                                        }}>LANDAO</Span>
                                    </Heading>
                                    <Heading level={4}>Land acquisition for the people, by the people.</Heading>
                                </Flex>

                                <Flex $style={{
                                    fDirection: "column",
                                    gap: ".5rem"
                                }}>
                                    <P $style={{
                                        queries: {
                                            920: {
                                                align: "center"
                                            }
                                        }
                                    }}>
                                        LANDAO is a decentralized organization for acquiring farmland and ranchland, uniquely designed and structured from a “We The People” perspective.
                                    </P>
                                    <P $style={{
                                        queries: {
                                            920: {
                                                align: "center"
                                            }
                                        }
                                    }}>
                                        As a DAO (Decentralized Autonomous Organization), we are highly transparent, rules-based, and decentralized, with our decisions made by our token holders. We place great emphasis on token holder protection and token value.
                                    </P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                gap: "1rem",
                                queries: {
                                    920: {
                                        hAlign: "center"
                                    },
                                    450: {
                                        fDirection: "column",
                                        vAlign: "center",
                                        hAlign: "center",
                                    }
                                }
                            }}>
                                <Link to={configs.appConfigs.appPath.APP_PREFIX}>
                                    <Button $style={{
                                        bg: "bg-2",
                                        kind: "radius",
                                        hover: "bg-hover",
                                        w: "12rem"
                                    }}>
                                        Get Started
                                    </Button>
                                </Link>
                                <a href="https://info.landao.org/tokenomics" target="_blank">
                                    <Button $style={{
                                        kind: "radius",
                                        border: "1px solid #1DAEFF",
                                        hover: "bg-2",
                                        w: "12rem"
                                    }}>
                                        Token Distribution
                                    </Button>
                                </a>
                            </Flex>
                        </Flex>
                        <Image src={Constants.Imgs.LNT} />
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" id="about" $style={{
                    w: "100%",
                    maxW: "1440px",
                    mb: "12rem"
                }}>
                    <Flex $style={{
                        hAlign: "space-between",
                        vAlign: "center",
                        w: "100%",
                        gap: "2rem",
                        queries: {
                            920: {
                                fDirection: "column"
                            }
                        }
                    }}>
                        <Image src={Constants.Imgs.Graph} />
                        <Flex $style={{
                            fDirection: "column",
                            maxW: "40rem",
                        }}>
                            <Flex $style={{
                                mb: "1.5rem",
                                fDirection: "column"
                            }}>
                                <Span $style={{
                                    color: "color-2"
                                }}>About us</Span>
                                <Heading level={3}>A new vision for farm and ranch land</Heading>
                            </Flex>
                            <P $style={{
                                mb: "2rem"
                            }}>Our DAO is built to continuously reinvest our profits, steadily compounding our income. Through investments in property enhancements and improvements as well as larger and larger properties, we will be able to achieve sustained and accelerated growth.
                            </P>
                            <Flex $style={{
                                p: ""
                            }}>
                                <a href="https://info.landao.org/" target="_blank">
                                    <Button $style={{
                                        kind: "radius",
                                        border: "1px solid #1DAEFF",
                                        hover: "bg-2"
                                    }}>
                                        More About us
                                    </Button>
                                </a>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" id="pros" $style={{
                    w: "100%",
                    maxW: "1440px",
                    hAlign: "center",
                    mb: "12rem"
                }}>
                    <Flex $style={{
                        fDirection: "column"
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            mb: "2rem"
                        }}>
                            <Flex $style={{
                                fDirection: "column",
                                vAlign: "center",
                                mb: "1rem"
                            }}>
                                <Span $style={{
                                    color: "color-2"
                                }}>Why choose us?</Span>
                                <Heading level={3}>Hard Assets - Transparent - Rules Based - Community Controlled </Heading>
                            </Flex>
                            <P $style={{
                                maxW: "45rem",
                                align: "center"
                            }}>
                                Inherent value derived from hard assets. Perpetual redeployment of income back into real estate. Core values of honesty and transparency. Actions based on community control and established, binding rules.
                            </P>
                        </Flex>
                        <Grid $style={{
                            columns: "4",
                            gap: "1rem",
                            $queries: {
                                920: {
                                    columns: '2'
                                },
                                480: {
                                    columns: "1"
                                }
                            }
                        }}>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'lock'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Safe and secure</Heading>
                                    <P>We strive for a safe and secure platform.</P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'privacy'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Privacy</Heading>
                                    <P>We will never sell your data, and we’ll never ask for more personal information than we need to operate your account.</P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'app'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: '1rem'
                                    }}>Integrated app</Heading>
                                    <P>Our app allows you full control of your account and participation in guiding the DAO in to the future.</P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'multi-currency'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Multi-currency support                                    </Heading>
                                    <P>Today we accept Ethereum, stable coins USDT and BUSD. More coins will be added soon.
                                    </P>
                                </Flex>
                            </Flex>
                        </Grid>
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" id="guide" $style={{
                    w: "100%",
                    maxW: "1440px",
                    hAlign: "center",
                    mb: "12rem"
                }}>
                    <Flex $style={{
                        fDirection: "column"
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            mb: "2rem"
                        }}>
                            <Flex $style={{
                                fDirection: "column",
                                vAlign: "center",
                                mb: "1rem"
                            }}>
                                <Span $style={{
                                    color: "color-2"
                                }}>Step by step guide</Span>
                                <Heading level={3}>Easy steps to success</Heading>
                            </Flex>
                            <P $style={{
                                maxW: "45rem",
                                align: "center"
                            }}>
                                It’s easy to join our community of token owners.
                            </P>
                        </Flex>
                        <Grid $style={{
                            columns: "3",
                            maxW: "60rem",
                            gap: "1rem",
                            $queries: {
                                920: {
                                    columns: '2'
                                },
                                480: {
                                    columns: "1"
                                }
                            }
                        }}>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "center"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'wallet-2'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "center",
                                    mb: "2rem"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Create Account</Heading>
                                    <P $style={{
                                        align: "center"
                                    }}>Sign up now and receive your personal wallet.</P>
                                </Flex>
                                <Link to={configs.appConfigs.appPath.SIGNUP_PREFIX}>
                                    <Button $style={{
                                        hover: "bg-2",
                                        kind: "radius",
                                        border: "1px solid #1DAEFF"
                                    }}>
                                        Learn more
                                    </Button>
                                </Link>
                            </Flex>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "center"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'payment'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "center",
                                    mb: "2rem"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Make Deposit</Heading>
                                    <P $style={{
                                        align: "center"
                                    }}>Easily add funds to your wallet using a wide range of coins.
                                    </P>
                                </Flex>
                                <a href="https://info.landao.org/how-to-buy-native-tokens/make-a-deposit" target="_blank">
                                    <Button $style={{
                                        hover: "bg-2",
                                        kind: "radius",
                                        border: "1px solid #1DAEFF"
                                    }}>
                                        Learn more
                                    </Button>
                                </a>
                            </Flex>
                            <Flex $style={{
                                border: "1px solid #353535",
                                p: "1.5rem",
                                radius: "1rem",
                                fDirection: "column",
                                vAlign: "center"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    background: "rgba(95,95,95,.2)",
                                    radius: "5rem",
                                    p: "1rem",
                                    mb: "2rem"
                                }}>
                                    <Icon icon={'trading'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "center",
                                    mb: "2rem"
                                }}>
                                    <Heading level={4} $style={{
                                        mb: "1rem"
                                    }}>Get Tokens</Heading>
                                    <P $style={{
                                        align: "center"
                                    }}>Purchase tokens to assure maximum growth.


                                    </P>
                                </Flex>
                                <a href="https://www.landao-introduction.org/landao-llc/get-tokens" target="_blank">
                                    <Button $style={{
                                        hover: "bg-2",
                                        kind: "radius",
                                        border: "1px solid #1DAEFF"
                                    }}>
                                        Learn more
                                    </Button>
                                </a>
                            </Flex>
                        </Grid>
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" id="vision" $style={{
                    vAlign: "center",
                    hAlign: "space-between",
                    mb: "12rem",
                    gap: '3rem',
                    queries: {
                        920: {
                            fDirection: "column"
                        }
                    }
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        maxW: "30rem",
                        queries: {
                            920: {
                                order: "2"
                            }
                        }
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            mb: "1rem"
                        }}>
                            <Span $style={{
                                color: "color-2"
                            }}>Our Vision</Span>
                            <Heading level={3}>Users from all over the world</Heading>
                        </Flex>
                        <P $style={{
                            mb: "2rem"
                        }}>
                            Our sole focus, at this time, is the acquisition of properties in the US, and we welcome participation from around the world. As LANDAO expands, we plan to explore similar arrangements in other countries as well.
                        </P>
                        <Flex>
                            <Link to={configs.appConfigs.appPath.SIGNIN_PREFIX}>
                                <Button $style={{
                                    bg: "bg-2",
                                    kind: "radius",
                                    hover: "bg-hover"
                                }}>
                                    Get Started
                                </Button>
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        queries: {
                            920: {
                                order: "1"
                            }
                        }
                    }}>
                        <Image src={Constants.Imgs.World} />
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" $style={{
                    w: "100%",
                    maxW: "1440px",
                    hAlign: "center",
                    mb: "12rem"
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        gap: "3rem"
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            mb: "2rem"
                        }}>
                            <Flex $style={{
                                fDirection: "column",
                                vAlign: "center",
                                mb: "1rem"
                            }}>
                                <Span $style={{
                                    color: "color-2"
                                }}>Our Features</Span>
                                <Heading level={3}>YOU are LANDAO.</Heading>
                            </Flex>
                            <P $style={{
                                maxW: "45rem",
                                align: "center"
                            }}>
                                Here are a few features to assure success.
                            </P>
                        </Flex>
                        <Flex $style={{
                            vAlign: "center",
                            hAlign: "center",
                            queries: {
                                920: {
                                    gap: "2rem",
                                    hAlign: "center"
                                },
                                450: {
                                    fDirection: "column"
                                }
                            }
                        }}>
                            <Flex $style={{
                                fDirection: "column"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    p: "1.5rem",
                                    radius: "1rem",
                                    fDirection: "column",
                                    vAlign: "center",
                                    mb: "2rem",
                                    maxW: "30rem",
                                    w: "100%"
                                }}>
                                    <Flex $style={{
                                        mb: "2rem"
                                    }}>
                                        <Icon icon={'account'} />
                                    </Flex>
                                    <Flex $style={{
                                        fDirection: "column",
                                        vAlign: "center",
                                        mb: "2rem"
                                    }}>
                                        <Heading level={4} $style={{
                                            mb: "1rem"
                                        }}>Free Account</Heading>
                                        <P $style={{
                                            align: "center"
                                        }}>All accounts a free and there are no fees for anything.
                                        </P>
                                    </Flex>
                                </Flex>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    p: "1.5rem",
                                    radius: "1rem",
                                    fDirection: "column",
                                    vAlign: "center",
                                    maxW: "30rem",
                                    w: "100%"
                                }}>
                                    <Flex $style={{
                                        mb: "2rem"
                                    }}>
                                        <Icon icon={'feedback'} />
                                    </Flex>
                                    <Flex $style={{
                                        fDirection: "column",
                                        vAlign: "center",
                                        mb: "2rem"
                                    }}>
                                        <Heading level={4} $style={{
                                            mb: "1rem"
                                        }}>Good Feedback</Heading>
                                        <P $style={{
                                            align: "center"
                                        }}>We strive for a functional, safe and secure platform. Your comments are valuable.
                                        </P>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                queries: {
                                    920: {
                                        display: "none"
                                    }
                                }
                            }}>
                                <Image src={Constants.Imgs.BTCGuide} />
                            </Flex>
                            <Flex $style={{
                                fDirection: "column"
                            }}>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    p: "1.5rem",
                                    radius: "1rem",
                                    fDirection: "column",
                                    vAlign: "center",
                                    mb: "2rem",
                                    maxW: "30rem"
                                }}>
                                    <Flex $style={{
                                        mb: "2rem"
                                    }}>
                                        <Icon icon={'star'} />
                                    </Flex>
                                    <Flex $style={{
                                        fDirection: "column",
                                        vAlign: "center",
                                        mb: "2rem"
                                    }}>
                                        <Heading level={4} $style={{
                                            mb: "1rem"
                                        }}>Community Proposals</Heading>
                                        <P $style={{
                                            align: "center"
                                        }}>Ideas from the community are what drives the organization. Proposals are presented, voted on and implemented.
                                        </P>
                                    </Flex>
                                </Flex>
                                <Flex $style={{
                                    border: "1px solid #353535",
                                    p: "1.5rem",
                                    radius: "1rem",
                                    fDirection: "column",
                                    vAlign: "center",
                                    maxW: "30rem"
                                }}>
                                    <Flex $style={{
                                        mb: "2rem"
                                    }}>
                                        <Icon icon={'support'} />
                                    </Flex>
                                    <Flex $style={{
                                        fDirection: "column",
                                        vAlign: "center",
                                        mb: "2rem"
                                    }}>
                                        <Heading level={4} $style={{
                                            mb: "1rem"
                                        }}>Member Support</Heading>
                                        <P $style={{
                                            align: "center"
                                        }}>All requests for support are handled in the order received.
                                        </P>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex data-aos="fade-up" id="teams" $style={{
                    fDirection: "column",
                    vAlign: "center",
                    mb: "12rem",
                    gap: "3rem"
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        vAlign: "center",
                        maxW: "40rem",
                        mb: "2rem"
                    }}>
                        <Span $style={{
                            color: "color-2"
                        }}>Team</Span>
                        <Heading level={3} $style={{
                            mb: "1rem"
                        }}>Our Team</Heading>
                        <P $style={{
                            align: "center"
                        }}>Questions? Feel free to reach out to our team.</P>
                    </Flex>
                    <Grid $style={{
                        columns: "2",
                        gap: "3rem",
                        $queries: {
                            768: {
                                columns: "1"
                            }
                        }
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>

                            <a href="mailto:admin@landao.org">
                                <Flex $style={{
                                    w: "17rem",
                                    h: "17rem",
                                    radius: "50rem",
                                    overflow: "hidden"
                                }}>
                                    <Image src={Constants.Imgs.CEO} />
                                </Flex>
                            </a>
                            <Flex $style={{
                                fDirection: "column",
                                vAlign: "center",
                                gap: ".5rem"
                            }}>
                                <Heading level={4}>Founder - Director</Heading>
                                <Span>LANDAO</Span>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <a href="mailto:shinobi@landao.org">

                                <Flex $style={{
                                    w: "17rem",
                                    h: "17rem",
                                    radius: "50rem",
                                    overflow: "hidden"
                                }}>
                                    <Image src={Constants.Imgs.CTO} />
                                </Flex>
                            </a>
                            <Flex $style={{
                                fDirection: "column",
                                vAlign: "center"
                            }}>
                                <Heading level={4}>CTO and CoFounder</Heading>
                                <Span>Tadashi Amano</Span>
                            </Flex>
                        </Flex>
                    </Grid>
                </Flex>
            </Flex>
            <Footer />
            <LandingSidebar />
        </Flex>
    )
}