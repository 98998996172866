import Flex from "components/basic/flex";
import Spinner from "components/basic/spinner";
import { Span } from "components/basic/text";

export default function Loader() {
    return (
        <Flex $style={{
            w: "100%",
            h: "100%",
            hAlign: "center",
            vAlign: "center"
        }}>
            <Spinner width="50" height="50" />
        </Flex>
    )
}

interface DataLoaderProps {
    label?:string
}

export const DataLoader = ({ label = 'Loading data...' }:DataLoaderProps) => {
    return(
        <Flex $style={{
            fDirection:"column",
            vAlign:"center",
            hAlign:"center",
            gap:"1rem",
            w:"100%", 
            h:"100%"
        }}>
            <Spinner width="40" height="40" />
            <Span>{label}</Span>
        </Flex>
    )
}