import styled from "styled-components";

interface IconProps {
    width?: string
    height?: string
    fill?: string
    hover?: string
}

export const IconWrapper = styled.span<IconProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width ? props.width : "initial"};
    height: ${props => props.height ? props.height : "initial"};
    transition: all .3s !important;

    svg, path {
        ${props => props.fill ? `fill:${props.fill}` : ''};
        ${props => props.fill ? `stroke:${props.fill}` : ''};
        transition: all .3s !important;
        width: ${props => props.width ? props.width : "initial"};
        height: ${props => props.height ? props.height : "initial"};
    }

    &:hover {
        svg, path {
            ${props => props.hover ? `fill:${props.hover}` : ''};
            ${props => props.hover ? `stroke:${props.hover}` : ''};
        }
    }
`