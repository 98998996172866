import appPath from "./path.config";

const appConfigs = {
    appPath,
    LGT_PRICE : 60000,
    ETHER_SCAN_API_KEY : '4531G33Y1EH3K62THAFVM9ATB5FEH89GSQ',
    ETH_SCAN_API_ENDPOINT : 'https://api.etherscan.io/api',
    BSC_SCAN_API_KEY : 'XVDQWRB4FGCEDY1F6JW7TWHN4Y8KSZZB1I',
    BSC_SCAN_API_ENDPOINT : 'https://api.bscscan.com/api',
    POLYGON_SCAN_API_KEY : 'NQR1HHHSNK6E57XB16WRZNIC6H3UECRZZV',
    POLYGON_SCAN_API_ENDPOINT : 'https://api.polygonscan.com/api',
}

export default  appConfigs;