const variables = {
    'primary': '#5D6DFD',
    'danger': '#E03F3F',

    'bg': '#090909',
    'bg-2' : '#1DAEFF',
    'bg-3' : '#0F0F13',
    'bg-hover':'#0094E7',
    'bg-hover-2':'#161616',
    'bg-sub': '#1A2D474D',
    'color': '#FFFFFF',
    'color-2': '#1DAEFF',
    'color-3': '#000000',
    'color-4': '#41CD7D',
    'color-5': '#EDA000',
    'placeholder-color': '#C1C1C1',

    'input-bg': '#ffffff',
    'input-color': '#9BA2B2',

    'font-size': '15px',
    'font-size-1': '4rem',
    'font-size-2': '3rem',
    'font-size-3': '1.75rem',
    'font-size-4': '1.5rem',
    'font-size-5': '1rem',
    'font-size-6': '0.8rem',
    'section-w': '1200px',
    'body-font': 'Mulish',
    'heading-font': 'Mulish'
}

export const smVariables = {
    'font-size': '12px',
    'font-size-1': '2.28rem',
    'font-size-2': '1.58rem',
    'font-size-3': '0.75rem',
    'font-size-4': '1rem',
}

export default variables;
