import axios from "axios";
import configs from "configs";

export const getLatestNews = async (userData:any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.NEWS_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX, {
            userData
        }
    )
    if (res.status === 200) {
        return res.data;
    }
}

export const addNews = async (title:string, description:string) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.NEWS_PREFIX +
        configs.serverConfigs.serverPath.CREATE_PREFIX, {
            title:title,
            description:description
        }
    )
    if (res.status === 200) {
        return res.data;
    }
}