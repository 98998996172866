import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import Icon from "components/basic/icon";
import Link from "components/basic/link";
import { P, Span } from "components/basic/text";
import configs from "configs";

export default function Footer() {
    return (
        <Flex $style={{
            vAlign: "center",
            hAlign: "center",
            p: "5rem 1rem 1rem 1rem",
            fDirection: "column",
            gap: "2rem"
        }}>
            <Flex $style={{
                w: "100%",
                maxW: "1440px",
                hAlign: "space-between",
                gap: "2rem",
                queries: {
                    768: {
                        fDirection: "column"
                    }
                }
            }}>
                <Flex $style={{
                    fDirection: "column",
                    maxW: "25rem",
                    gap: "1rem"
                }}>
                    <Link to={configs.appConfigs.appPath.PUBLIC_PREFIX}>
                        <Icon icon={'logo'} />
                    </Link>
                    <P $style={{
                        mb: "1rem"
                    }}>
                        As a DAO (Decentralized Autonomous Organization), we are highly transparent, rules-based, and decentralized, with our decisions made by our token holders. We place great emphasis on token holder protection and token value.

                    </P>
                    <Flex $style={{
                        gap: "1.5rem"
                    }}>
                        <a href="https://discord.gg/tSde4F3N" target="_blank">
                            <Flex $style={{
                                w:'3rem',
                                h:'3rem',
                                hAlign:"center",
                                vAlign:"center",
                                radius: "2rem",
                                border: "1px solid #00C4F4"
                            }}>
                                <Icon icon={"discord"} />
                            </Flex>
                        </a>
                        <a href="https://twitter.com/i/communities/1721588713916899722" target="_blank">
                            <Flex $style={{
                                p: ".5rem",
                                radius: "2rem",
                                w:'3rem',
                                h:'3rem',
                                hAlign:"center",
                                vAlign:"center",
                                border: "1px solid #00C4F4"
                            }}>
                                <Icon icon={"twitter"} />
                            </Flex>
                        </a>
                    </Flex>
                </Flex>
                <Flex $style={{
                    gap: "5rem",
                    queries: {
                        480: {
                            fDirection: "column"
                        }
                    }
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        gap: "1rem"
                    }}>
                        <Heading level={4}>Useful Links</Heading>
                        <a href="https://info.landao.org/" target="_blank">
                            <Span>Introduction</Span>
                        </a>
                        <a href="https://info.landao.org/pre-sell-information" target="_blank">
                            <Span>Pre-Sell</Span>
                        </a>
                        <a href="https://info.landao.org/white-paper" target="_blank">
                            <Span>White Paper</Span>
                        </a>
                        <a href="https://info.landao.org/terms-and-conditions-of-use" target="_blank">
                            <Span>Terms & Conditions</Span>
                        </a>
                    </Flex>
                    <Flex $style={{
                        fDirection: "column",
                        gap: "1rem"
                    }}>
                        <Heading level={4}>COMMUNITY</Heading>
                        <a href="mailto:support@landao.org">Support</a>
                        <a href="mailto:partners@landao.org">Partners</a>
                        <a href="mailto:info@landao.org">Suggestions </a>
                        <a href="mailto:media@landao.org">Media </a>
                    </Flex>
                    <Flex $style={{
                        fDirection: "column",
                        gap: "1rem"
                    }}>
                        <Heading level={4}>Contact</Heading>
                        <Flex $style={{
                            gap: "1rem"
                        }}>
                            <Icon icon={'phone'} />
                            <Span>(307) 225-2451</Span>
                        </Flex>
                        <Flex $style={{
                            gap: "1rem"
                        }}>
                            <Icon icon={'email'} />
                            <Span>contact@landao.org</Span>
                        </Flex>
                        <Flex $style={{
                            gap: "1rem"
                        }}>
                            <Icon icon={'location'} />
                            <Span>Cheyenne WY</Span>
                        </Flex>
                    </Flex>
                </Flex>

            </Flex>
            <Flex $style={{
                w: "100%",
                maxW: "1440px",
            }}>
                <Span>Copyright © 2023 LANDAO.org</Span>
            </Flex>
        </Flex>
    )
}