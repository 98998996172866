import { ethers } from 'ethers';

export const getDateFromTimeStamp = (timestamp: string) => {

    if(!timestamp) {
        return '';
    }

    const date = new Date(parseInt(timestamp) * 1000);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${year}-${month + 1}-${day}`;
}

export const formatValue = (value: string, tokenSymbol: string) => {

    if(!tokenSymbol || !value) {
        return '';
    }

    switch (tokenSymbol) {
        case 'eth':
            return ethers.formatEther(value);
        case 'polygon':
            return ethers.formatEther(value);
        case 'bnb':
            return ethers.formatEther(value);
        case 'usdt':
            return ethers.formatUnits(value, 6);
        case 'busd':
            return ethers.formatUnits(value, 18);
        case 'lnt':
            return ethers.formatUnits(value, 18);
        case 'lgt':
            return ethers.formatUnits(value, 18);
        default:
            return ethers.formatEther(value);
    }
}

export const formatBigNumber = (num: number) => {

    if (num > 1000000000) {
        const numInBillion = (num / 1000000000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return numInBillion + 'B';
    }

    if (num > 1000000) {
        const numInMillion = (num / 1000000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return numInMillion + "M";
    }

    if (num > 1000) {
        const numInThousands = (num / 1000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return numInThousands + "K";
    }

    return num;
}

export const convertTimeStamp = (timeStamp: string) => {
    const date = new Date(Number(timeStamp));
    const humanReadableDate = date.toLocaleString();
    return humanReadableDate;
}