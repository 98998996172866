import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { actions as appActions } from "store/app.slice";
import LandingSidebarUI from "./ui";
import Cookies from 'js-cookie';

const LandingSidebar = () => {

    const landState = useSelector((state: any) => state.app.land);
    const [isOpen, setIsOpen] = useState(landState);
    const [loginStatus, setLoginStatus] = useState(false);
    const cookieValue = Cookies.get('token');

    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(landState)
    }, [landState])

    useEffect(() => {
        cookieValue && setLoginStatus(true);
    }, [cookieValue])


    const handleClick = () => {
        dispatch(appActions.toggleLand());
    };

    return (
        <LandingSidebarUI 
            handleClose={handleClick} 
            isOpen={isOpen}
            loginStatus={loginStatus}
        />
    )
}

export default LandingSidebar;