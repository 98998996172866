const serverPath = {
    UTIL_PREFIX: '/util',
    AUTH_PREFIX: '/auth',
    ADMIN_PREFIX: '/admin',
    PRICE_PREFIX: '/price',
    WALLET_PREFIX: '/wallet',
    PRPOSAL_PREFIX: '/proposal',
    PASSPORT_PREFIX: '/passport',
    NEWS_PREFIX: '/news',
    LOGIN_PREFIX: '/login',
    REGISTER_PREFIX: '/register',
    CHANGE_PREFIX: '/change',
    CHECK_PREFIX: '/check',
    LOGOUT_PREFIX: '/logout',
    VERIFY_PREFIX: '/verify',
    FORGOT_PREFIX: '/forgot',
    CHANGE_PASSWORD_PREFIX: '/pchange',
    PAYMENT_PREFIX: '/payment',
    BUY_PREFIX: '/buy',
    LNT_PREFIX: '/lnt',
    BTC_PREFIX: '/btc',
    ETH_PREFIX: '/eth',
    COIN_PREFIX: '/coin',
    TOKEN_PREFIX: '/token',
    USDT_PREFIX: '/usdt',
    BUSD_PREFIX: '/busd',
    BNB_PREFIX: '/bnb',
    BALANCE_PREFIX: '/balance',
    POLYGON_PREFIX: '/polygon',
    LGT_PREFIX: '/lgt',
    USD_PREFIX: '/usd',
    GET_PREFIX: '/get',
    UNCONFIRM_PREFIX: '/unconfirm',
    TRX_PREFIX: '/trx',
    UPDATE_PREFIX: '/update',
    US_PREFIX: '/us',
    DELETE_PREFIX: '/delete',
    VOTE_PREFIX: '/vote',
    AGAINST_PREFIX: '/against',
    LONG_PREFIX: '/long',
    CREATE_PREFIX: '/create',
    SEND_PREFIX: '/send'
}

export default serverPath;