import configs from "configs";
import { lazy } from "react";

const Dashboard = lazy(() => import('pages/private/dashboard'));
const OverviewPage = lazy(() => import('pages/private/overview'));
const PayPage = lazy(() => import('pages/private/pay'));
const PaymentPage = lazy(() => import('pages/private/payment'));
const ProfilePage = lazy(() => import('pages/private/profile'));
const ProposalPage = lazy(() => import('pages/private/proposal'));
const SettingPage = lazy(() => import('pages/private/setting'));
const WithDrawPage = lazy(() => import('pages/private/withdraw'));
const VerifyPage = lazy(() => import('pages/private/verify'));
const CreateProposalPage = lazy(() => import('pages/private/createProposal'));
const AdminPage = lazy(() => import('pages/private/admin'));

const privateRoutes = [
    {
        path: configs.appConfigs.appPath.APP_PREFIX,
        element: <Dashboard />
    },
    {
        path: configs.appConfigs.appPath.PROPOSAL_PREFIX,
        element: <ProposalPage />
    },
    {
        path: configs.appConfigs.appPath.PASSPORT_PREFIX,
        element: <VerifyPage />
    },
    {
        path: configs.appConfigs.appPath.PAYMENT_PREFIX,
        element: <PaymentPage />
    },
    {
        path: configs.appConfigs.appPath.OVERVIEW_PREFIX,
        element: <OverviewPage />
    },
    {
        path: configs.appConfigs.appPath.PROFILE_PREFIX,
        element: <ProfilePage />
    },
    {
        path: configs.appConfigs.appPath.PAY_PREFIX,
        element: <PayPage />
    },
    {
        path: configs.appConfigs.appPath.WITHDRAW_PREFIX,
        element: <WithDrawPage />
    },
    {
        path: configs.appConfigs.appPath.SETTING_PREFIX,
        element: <SettingPage />
    },
    {
        path: configs.appConfigs.appPath.PROPOSAL_PREFIX + configs.appConfigs.appPath.CREATE_PREFIX,
        element: <CreateProposalPage />
    },
    {
        path: configs.appConfigs.appPath.ADMIN_PREFIX,
        element: <AdminPage />
    }
]

export default privateRoutes;