import configs from "configs";
import ChangePasswordPage from "pages/public/change";
import ForgotPasswordPage from "pages/public/forgot";
import LandingPage from "pages/public/landing";
import SigninPage from "pages/public/signin";
import SignupPage from "pages/public/signup";
import { Navigate } from "react-router-dom";

const publicRoutes = [
    {
        path: configs.appConfigs.appPath.PUBLIC_PREFIX,
        element: <LandingPage/>
    },
    {
        path: configs.appConfigs.appPath.SIGNIN_PREFIX,
        element: <SigninPage />
    },
    {
        path: configs.appConfigs.appPath.SIGNUP_PREFIX,
        element: <SignupPage />
    },
    {
        path: configs.appConfigs.appPath.FORGOT_PREFIX,
        element: <ForgotPasswordPage />
    },
    {
        path: configs.appConfigs.appPath.CHANGE_PREFIX + '/:token',
        element: <ChangePasswordPage />
    },
    {
        path: configs.appConfigs.appPath.PUBLIC_PREFIX,
        element: <Navigate to={configs.appConfigs.appPath.APP_PREFIX}/>
    }
]

export default publicRoutes;