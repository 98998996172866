import axios from "axios";
import configs from "configs";

export const getBTCBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.BTC_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getETHBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.ETH_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getUSDTBalance = async (userData: any, type: number) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.USDT_PREFIX, {
        userData: userData,
        type: type
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getBUSDBalance = async (userData: any, type: number) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.BUSD_PREFIX, {
        userData: userData,
        type: type
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getBNBBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.BNB_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }


}

export const getPolygonBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.POLYGON_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getTokenBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.TOKEN_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getLNTBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.LNT_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const getLGTBalance = async (userData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.PAYMENT_PREFIX +
        configs.serverConfigs.serverPath.GET_PREFIX +
        configs.serverConfigs.serverPath.LGT_PREFIX, {
        userData: userData
    })
    if (res.status === 200) {
        return res.data;
    }


}

export const getTransactions = async (userData: any) => {

    const etherTransactions = await getEthTransactions(userData.payment.ethWallet.address);
    const etherTokenTransactions = await getEthTokenTransactions(userData.payment.ethWallet.address);
    const bscTransactions = await getBscTransactions(userData.payment.ethWallet.address);
    const bscTokenTransactions = await getBscTokenTransactions(userData.payment.ethWallet.address);
    const polygonTransactions = await getPolygonTransactions(userData.payment.ethWallet.address);
    const polygonTokenTransactions = await getPolygonTokenTransactions(userData.payment.ethWallet.address);

    const concatenatedArray = etherTransactions.concat(
        etherTokenTransactions,
        bscTransactions,
        bscTokenTransactions,
        polygonTransactions,
        polygonTokenTransactions
    );

    console.log(concatenatedArray);
    const filteredData = concatenatedArray.filter((item: any) => item.value !== '0');

    return {
        trxs: filteredData,
        totalLength: filteredData.length
    };
}

export const getEthTransactions = async (address: string) => {
    try {
        let etherTransactions = [];

        const etherResponse = await axios.get(configs.appConfigs.ETH_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'txlist',
                address: address,
                apikey: configs.appConfigs.ETHER_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });

        if (etherResponse.status === 200) {

            let tempTrxs = etherResponse?.data?.result?.length ? etherResponse?.data?.result : [];
            
            etherTransactions = tempTrxs.map((item: any) => {
                return { ...item, tokenSymbol: 'ETH' };
            });;
        }


        return etherTransactions;

    } catch (err) {
        console.log('Error when get ETH Transactions', err);
    }
}

export const getEthTokenTransactions = async (address: string) => {
    try {
        let etherTokenTransactions = [];

        const etherTokenResponse = await axios.get(configs.appConfigs.ETH_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'tokentx',
                address: address,
                apikey: configs.appConfigs.ETHER_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });

        if (etherTokenResponse.status === 200) {

            etherTokenTransactions = etherTokenResponse?.data?.result?.length ? etherTokenResponse?.data?.result : [];
        }

        return etherTokenTransactions;
    } catch (err) {
        console.log('Error when get ETH Token Transactions', err);
    }
}

export const getBscTransactions = async (address: string) => {
    try {
        let bscTransactions = [];

        const bscResponse = await axios.get(configs.appConfigs.BSC_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'txlist',
                address: address,
                apikey: configs.appConfigs.BSC_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });

        if (bscResponse.status === 200) {
            let tempTrxs = bscResponse?.data?.result?.length ? bscResponse?.data?.result : [];

            bscTransactions = tempTrxs.map((item: any) => {
                return { ...item, tokenSymbol: 'BNB' };
            });;
        }

        return bscTransactions;
    } catch (err) {
        console.log('Error when get BSC Transactions', err);
    }
}

export const getBscTokenTransactions = async (address: string) => {
    try {
        let bscTokenTransactions = [];

        const bscTokenResponse = await axios.get(configs.appConfigs.BSC_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'tokentx',
                address: address,
                apikey: configs.appConfigs.BSC_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });


        if (bscTokenResponse.status === 200) {
            bscTokenTransactions = bscTokenResponse?.data?.result?.length ? bscTokenResponse?.data?.result : [];
        }
        return bscTokenTransactions;
    } catch (err) {
        console.log('Error when get BSC Token Transactions', err);
    }
}

export const getPolygonTransactions = async (address: string) => {
    try {
        let polygonTransactions = [];

        const polygonResponse = await axios.get(configs.appConfigs.POLYGON_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'txlist',
                address: address,
                apikey: configs.appConfigs.POLYGON_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });

        if (polygonResponse.status === 200) {
            let tempTrxs = polygonResponse?.data?.result?.length ? polygonResponse?.data?.result : [];

            polygonTransactions = tempTrxs.map((item: any) => {
                return { ...item, tokenSymbol: 'POLYGON' };
            });
        }

        return polygonTransactions;

    } catch (err) {
        console.log('Error when get Polygon Transactions', err);
    }
}

export const getPolygonTokenTransactions = async (address: string) => {
    try {
        let polygonTokenTransactions = [];

        const polygonTokenResponse = await axios.get(configs.appConfigs.POLYGON_SCAN_API_ENDPOINT, {
            params: {
                module: 'account',
                action: 'tokentx',
                address: address,
                apikey: configs.appConfigs.POLYGON_SCAN_API_KEY,
                startblock: 0,
                endblock: 99999999
            },
            timeout: 99999999
        });

        if (polygonTokenResponse.status === 200) {
            polygonTokenTransactions = polygonTokenResponse?.data?.result?.length ? polygonTokenResponse?.data?.result : [];
        }

        return polygonTokenTransactions;

    } catch (err) {
        console.log('Error when get Polygon Token Transactions', err);
    }
}

const getPaymentApi = {
    getBTCBalance,
    getETHBalance,
    getUSDTBalance,
    getBUSDBalance,
    getBNBBalance,
    getLNTBalance,
    getLGTBalance
}

export default getPaymentApi;