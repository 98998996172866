import Flex from "components/basic/flex";
import HeaderComponent from "components/pages/header";
import Loader from "components/pages/loader";
import SidebarComponent from "components/pages/sidebar";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { actions as userActions } from 'store/user.slice';
import { actions as appActions } from 'store/app.slice';
import { getUSDPriceOfCoins } from "utils/payment.util";

import configs from "configs";
import { openNotification } from "utils/toast.util";
import { checkToken } from "apis/auth";
import { getLatestNews } from "apis/news";

const { AppHeader } = HeaderComponent;
const { AppSidebar } = SidebarComponent;

export default function PrivatePages() {

    const userData = useSelector((state: any) => state.user.userData);
    const dispatch = useDispatch();
    const navigator = useNavigate();

    useEffect(() => {
        init();
    }, [userData._id])

    const init = async () => {
        if (!!userData._id) {
            await getCoinPrice();
        } else {
            await checkUser();
        }
    }

    const checkUser = async () => {
        try {
            const res = await checkToken();
            dispatch(userActions.setUserData(res.user));
        } catch (err: any) {
            switch (err.response.data.msg) {
                case 'TOKEN IS INVALID':
                    navigator(configs.appConfigs.appPath.SIGNIN_PREFIX);
                    openNotification('Token is invalid.')
                    break;
                default:
                    openNotification(err.response.data.msg)
                    break;
            }
        }
    }

    const getCoinPrice = async () => {
        try {
            const coinPrice = await getUSDPriceOfCoins();

            dispatch(appActions.setCurrencyPrice(coinPrice));
            await getNews();
        } catch (err: any) {
            switch (err.response.data.msg) {
                case 'TOKEN IS INVALID':
                    navigator(configs.appConfigs.appPath.SIGNIN_PREFIX);
                    openNotification('Token is invalid.')
                    break;
                default:
                    openNotification(err.response.data.msg)
                    break;
            }
        }

    }

    const getNews = async () => {
        const res = await getLatestNews(userData);
        const withNewsData = {
            ...userData,
            latestNews: res.news,
        };

        const length = localStorage.getItem('length');


        if (!!length) {
            if (res.news.length > length) {
                withNewsData.newsStatus = true;
            } else {
                withNewsData.newsStatus = false;
            }
        } else {
            withNewsData.newsStatus = true;
        }
        localStorage.setItem('length', res.news.length)
        dispatch(userActions.setUserData(withNewsData));

    }

    return (
        <Flex $style={{
            fDirection: "column",
            minH: "100vh"
        }}>
            <AppHeader />
            <Flex $style={{
                flex: "1"
            }}>
                <AppSidebar />
                <Flex $style={{
                    hAlign: "center",
                    flex: "1",
                    overflow: "auto"
                }}>
                    <Suspense fallback={<Loader />}>
                        <Outlet />
                    </Suspense>
                </Flex>
            </Flex>
        </Flex>
    )
}