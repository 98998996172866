import axios from "axios"
import configs from "configs"

interface authProps {
    email: string
    password: string
}

export const doLogin = async ({ email, password }: authProps) => {
    try {
        const res = await axios.post(configs.serverConfigs.serverPath.AUTH_PREFIX + configs.serverConfigs.serverPath.LOGIN_PREFIX, {
            email: email,
            password: password
        })
        if (res.status === 200) {
            return res
        }
    } catch (err: any) {
        return err.response.data.msg;
    }
}

export const doRegister = async ({ email, password }: authProps) => {
    try {
        const res = await axios.post(configs.serverConfigs.serverPath.AUTH_PREFIX + configs.serverConfigs.serverPath.REGISTER_PREFIX, {
            email: email,
            password: password
        })
        if (res.status === 200) {
            return res.data.msg;
        }
    } catch (err: any) {
        return err.response.data.msg;
    }
}

export const doChange = async (formData: any) => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.AUTH_PREFIX +
        configs.serverConfigs.serverPath.CHANGE_PREFIX, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const doChangePassword = async (id: string, password: string) => {
    try {
        const res = await axios.post(configs.serverConfigs.serverPath.AUTH_PREFIX + configs.serverConfigs.serverPath.CHANGE_PASSWORD_PREFIX, {
            id: id,
            password: password
        })
        if (res.status === 200) {
            return res.data;
        }
    } catch (err: any) {
        return err.response.data.msg;
    }
}

export const verifyAuth = async (formData: any) => {
    const res = await axios.post(configs.serverConfigs.serverPath.AUTH_PREFIX + configs.serverConfigs.serverPath.VERIFY_PREFIX, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    if (res.status === 200) {
        return res.data;
    }

}

export const forgotPassword = async (email: string) => {
    try {
        const res = await axios.post(
            configs.serverConfigs.serverPath.AUTH_PREFIX +
            configs.serverConfigs.serverPath.FORGOT_PREFIX,
            {
                email: email
            }
        )
        if (res.status === 200) {
            return res.data;
        }
    } catch (err: any) {
        return err.response.data;
    }
}

export const changeForgotPassword = async (token: string, password: string) => {
    try {
        const res = await axios.post(
            configs.serverConfigs.serverPath.AUTH_PREFIX +
            configs.serverConfigs.serverPath.FORGOT_PREFIX +
            configs.serverConfigs.serverPath.CHANGE_PREFIX,
            {
                token: token,
                password: password
            }
        )

        if (res.status === 200) {
            return res.data;
        }
    } catch (err: any) {
        return err.response.data;
    }
}

export const checkToken = async () => {
    const res = await axios.post(
        configs.serverConfigs.serverPath.AUTH_PREFIX +
        configs.serverConfigs.serverPath.CHECK_PREFIX
    )

    if (res.status === 200) {
        return res.data;
    }

}

export const logout = async () => {
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // const res = await axios.post(
    //     configs.serverConfigs.serverPath.AUTH_PREFIX +
    //     configs.serverConfigs.serverPath.LOGOUT_PREFIX
    // )

    // if (res.status === 200) {
    //     return res.data;
    // }

}