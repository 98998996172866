import { useState } from "react";
import ForgotPasswordPageUI from "./ui";
import { forgotPassword } from "apis/auth";
import { openNotification } from "utils/toast.util";

export default function ForgotPasswordPage() {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEmail = (e:any) => {
        setEmail(() => e.target.value);
    }

    const confirmChangePassword = async (e:any) => {
        setLoading(true);
        e.preventDefault();
        const res = await forgotPassword(email);
        openNotification(res.msg);
        setLoading(false);
    }

    return (
        <ForgotPasswordPageUI
            handleEmail={handleEmail}
            confirmChangePassword={confirmChangePassword}
            loading={loading}
        />
    )
}
