import { useState } from "react";
import Form from "./form";
import { doRegister } from "apis/auth";
import { openNotification } from "utils/toast.util";

export default function SignupPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [loading, setLoading] = useState(false);

    const changeEmail = (e:any) => {
        setEmail(() => e.target.value);
    }
    
    const changePassword = (e:any) => {
        setPassword(() => e.target.value);
    }
    
    const changeCpassword = (e:any) => {
        setCpassword(() => e.target.value);
    }

    const submitRegister = async (e:any) => {
        e.preventDefault();
        setLoading(true);
        if(password === cpassword) {
            const res = await doRegister({email, password});
            openNotification(res)
        } else {
            openNotification("Confirm your password !")
        }
        setLoading(false);
    }

    return (
        <Form isLoading={loading} email={changeEmail} password={changePassword} cpassword={changeCpassword} submit={submitRegister} />
    )
}