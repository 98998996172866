import { Oval } from 'react-loader-spinner'

interface SpinnerProps {
    width?: string
    height?: string
    color?: string
}

export default function Spinner({ width = "20", height = "20", color = "white" }: SpinnerProps) {
    return (
        <Oval
            height={width}
            width={height}
            color={color}
            secondaryColor="#70707C"
        />
    )
}