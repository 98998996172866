import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { actions as appActions } from "store/app.slice";
import { actions as userActions } from "store/user.slice";
import NewsSidebarUI from "./ui";

const NewsSidebar = () => {

    const newsState = useSelector((state: any) => state.app.news);
    const userData = useSelector((state: any) => state.user.userData);

    const [isOpen, setIsOpen] = useState(newsState);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsOpen(newsState)
    }, [newsState])


    const handleClick = () => {
        dispatch(appActions.toggleNews());
    };

    const changeNewsStatus = () => {
        dispatch(userActions.setUserData({
            ...userData,
            newsStatus: false
        }))
        dispatch(appActions.toggleNews());
    }

    return (
        <NewsSidebarUI
            handleClose={handleClick}
            isOpen={isOpen}
            userData={userData}
            changeNewsStatus={changeNewsStatus}
        />
    )
}

export default NewsSidebar;