import Flex from "components/basic/flex";
import Icon from "components/basic/icon";
import Link from "components/basic/link";
import { Span } from "components/basic/text";
import configs from "configs";

interface SidebarProps {
    isOpen: boolean,
    loginStatus: boolean,
    handleClose: any
}

const LandingSidebarUI = ({ isOpen, loginStatus, handleClose }: SidebarProps) => {

    return (
        <Flex as="sidebar" $style={{
            minW: "25rem",
            fDirection: "column",
            gap: "2rem",
            background: "#000000",
            position: "fixed",
            zIndex: "99",
            left: "0",
            bottom: "0",
            top: "0",
            p: "3rem 1rem 1rem 1rem",
            overflow: "auto",
            queries: {
                9999: !isOpen ? {
                    w: "0",
                    minW: "0",
                    p: "0",
                    overflow: "hidden"
                } : {
                    w: "100%",
                    maxW: "25rem",
                    minW: "0"
                }
            }
        }}>
            <Flex $style={{
                fDirection: "column",
                gap: "1rem"
            }}>
                <a href="#about" onClick={handleClose}>
                    <Span level={5}>About</Span>
                </a>
                <a href="#pros" onClick={handleClose}>
                    <Span level={5}>Pros</Span>
                </a>
                <a href="#vision" onClick={handleClose}>
                    <Span level={5}>Vision</Span>
                </a>
                <a href="#teams" onClick={handleClose}>
                    <Span level={5}>Teams</Span>
                </a>
                <Link
                    onClick={handleClose}
                    to={
                        loginStatus ?
                            configs.appConfigs.appPath.APP_PREFIX
                            :
                            configs.appConfigs.appPath.SIGNIN_PREFIX
                    }
                >
                    <Span>Go to DAO</Span>
                </Link>
            </Flex>
            <Flex $style={{
                position: "absolute",
                top: "20px",
                right: "50px"
            }}>
                <Icon onClick={handleClose} icon={'close-white'} />
            </Flex>
        </Flex>
    )
}

export default LandingSidebarUI;