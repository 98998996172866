import { useState } from "react";
import Form from "./form";
import { doLogin } from "apis/auth";
import { openNotification } from "utils/toast.util";
import { useNavigate } from "react-router-dom";
import configs from "configs";
import { useDispatch } from "react-redux";
import { actions as userActions } from 'store/user.slice';

export default function SigninPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const changeEmail = (e: any) => {
        setEmail(() => e.target.value);
    }

    const changePassword = (e: any) => {
        setPassword(() => e.target.value);
    }

    const submitLogin = async (e: any) => {
        setLoading(true);
        e.preventDefault();
        const res = await doLogin({ email, password });

        if (res.status === 200) {
            dispatch(userActions.setUserData(res.data));
            openNotification("Welcome to Landao");
            navigator(configs.appConfigs.appPath.APP_PREFIX);
            return;
        }
        res && openNotification(res)
        setLoading(false);
    }

    return (
        <Form isLoading={loading} email={changeEmail} password={changePassword} submit={submitLogin} />
    )
}