import BG from 'assets/imgs/bg.jpg';
import Avatar1 from 'assets/imgs/img-1.png';
import Avatar2 from 'assets/imgs/img-2.png';
import Avatar3 from 'assets/imgs/img-3.png';
import Avatar4 from 'assets/imgs/img-4.png';
import Avatar5 from 'assets/imgs/img-5.png';

import CEO from 'assets/imgs/ceo.png'
import CTO from 'assets/imgs/cto.jpg';

import BgAvatar1 from 'assets/imgs/avatar-1.png';

import Graph from 'assets/imgs/graph.png';

import World from 'assets/imgs/world.png';

import BTCGuide from 'assets/imgs/btc-guide.png';

import ETH from 'assets/imgs/eth.png';
import LNT from 'assets/imgs/lnt.png';

const Imgs = {
    BG,
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    BgAvatar1,
    Graph,
    World,
    BTCGuide,
    ETH,
    CEO,
    LNT,
    CTO
}

export default Imgs;