import { getBNBBalance, getBUSDBalance, getETHBalance, getLGTBalance, getLNTBalance, getPolygonBalance, getUSDTBalance } from "apis/payment/get";
import { getCoinPrice } from "apis/utils";

export const shortenAddress = (address: string) => {
  if (!address) {
    return '';
  }

  const addressPrefix = address.substring(0, 6); // Keep the first 6 characters
  const addressSuffix = address.substring(address.length - 4); // Keep the last 4 characters

  let ellipsis = '';
  if (address.length > 8) {
    ellipsis = '...';
  }

  const shortenedAddress = `${addressPrefix}${ellipsis}${addressSuffix}`;
  return shortenedAddress;
};

export const getTotalBalance = async (userData: any) => {
  const ethBalance = await getETHBalance(userData);
  const bnbBalance = await getBNBBalance(userData);
  const polygonBalance = await getPolygonBalance(userData);
  const busdBalance = await getBUSDBalance(userData, 4);
  const usdtBalance = await getUSDTBalance(userData, 4);
  const lgtBalance = await getLGTBalance(userData);
  const lntBalance = await getLNTBalance(userData);

  const userBalance = {
    ETH: parseFloat(ethBalance.balance),
    BNB: parseFloat(bnbBalance.balance),
    POLYGON: parseFloat(polygonBalance.balance),
    BUSD: parseFloat(busdBalance.balance),
    USDT: parseFloat(usdtBalance.balance),
    LGT: parseFloat(lgtBalance.balance),
    LNT: parseFloat(lntBalance.balance)
  }

  return userBalance;
}

export const getUSDPriceOfCoins = async () => {
  const bnbPrice = await getCoinPrice({
    symbol: 'BNB'
  })

  const ethPrice = await getCoinPrice({
    symbol: 'ETH'
  })

  const polygonPrice = await getCoinPrice({
    symbol: 'MATIC'
  })

  const coinBalance = {
    BNB: bnbPrice,
    ETH: ethPrice,
    POLYGON: polygonPrice,
    BUSD: 1,
    USDT: 1
  }

  return coinBalance;
}