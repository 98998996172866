import axios from 'axios';
import configs from 'configs';

interface getCoinPriceProps {
    symbol: string
}

export const getCoinPrice = async ({ symbol }: getCoinPriceProps) => {
    const res = await axios.post(configs.serverConfigs.serverPath.UTIL_PREFIX + configs.serverConfigs.serverPath.PRICE_PREFIX, {
        symbol: symbol
    })


    if (res.status === 200) {
        return res.data.price;
    }
}