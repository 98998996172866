import configs from "configs";
import { createBrowserRouter } from "react-router-dom";
import publicRoutes from "./public.route";
import privateRoutes from "./private.route";
import PrivatePages from "pages/private";

const mainRouter = createBrowserRouter([
    {
        path: configs.appConfigs.appPath.PUBLIC_PREFIX,
        children: publicRoutes
    },
    {
        path: configs.appConfigs.appPath.APP_PREFIX,
        element: <PrivatePages />,
        children: privateRoutes
    }
]);

export default mainRouter;