import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import { Span } from "components/basic/text";
import Button from "components/basic/button";
import { convertTimeStamp } from "utils/other.util";

interface SidebarProps {
    isOpen: boolean,
    handleClose: any
    changeNewsStatus: any
    userData: any
}

const NewsSidebarUI = ({ isOpen, handleClose, changeNewsStatus, userData }: SidebarProps) => {

    return (
        <Flex as="sidebar" $style={{
            w: "25rem",
            fDirection: "column",
            gap: "2rem",
            background: "#000000",
            position: "fixed",
            zIndex: "99",
            right: "0",
            bottom: "0",
            top: "0",
            transition: "all .3s ease-in-out",
            p: "3rem 1rem 1rem 1rem",
            overflow: "auto",
            queries: {
                9999: !isOpen ? {
                    w: "0",
                    right: "-5rem",
                    overflow: "hidden"
                } : {
                    w: "25rem"
                }
            }
        }}>
            <Flex $style={{
                hAlign: "space-between",
                vAlign:"center"
            }}>
                <Heading level={5} $style={{
                    white: "nowrap"
                }}>LANDAO News</Heading>
                <Flex onClick={changeNewsStatus} $style={{
                    cursor:"pointer"
                }}>
                    <Span>Mark as Read</Span>
                </Flex>
            </Flex>
            <Flex as="ul" $style={{
                fDirection: "column",
                gap: "1rem"
            }}>
                {
                    !!userData.latestNews && (userData.latestNews.length === 0 && !userData.newsStatus ?
                        <Flex as="li" $style={{
                            hAlign: "space-between",
                            background: "#0F0F13",
                            p: "1rem",
                            radius: "1rem",
                            h: "30rem"
                        }}>
                            <Span $style={{
                                white: "nowrap"
                            }}>There is no news</Span>
                        </Flex>
                        :
                        userData.latestNews.map((item: any) => {
                            return (
                                <Flex as="li" $style={{
                                    hAlign: "space-between",
                                    background: "#0F0F13",
                                    p: "1rem",
                                    radius: "1rem",
                                    fDirection: "column",
                                    gap: "1rem"
                                }}>
                                    <Heading level={5}>
                                        {
                                            item.title
                                        }
                                    </Heading>
                                    <Flex $style={{
                                        hAlign: "space-between"
                                    }}>
                                        <Span $style={{
                                        }}>{item.content}</Span>
                                        <Span>
                                            {
                                                convertTimeStamp(item.time)
                                            }
                                        </Span>
                                    </Flex>
                                </Flex>
                            )
                        }))
                }
            </Flex>
            <Flex $style={{
            }}>
                <Button $style={{
                    bg: "color-2",
                    w: "100%",
                    kind: "radius",
                    hover: "bg-hover"
                }} onClick={handleClose}>Close</Button>
            </Flex>
        </Flex>
    )
}


export default NewsSidebarUI;