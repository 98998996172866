import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    menu: false,
    news: false,
    land: false,
    activeCurrency: {},
    currencyPrice: {}
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.menu = !state.menu;
        },
        toggleNews: (state) => {
            state.news = !state.news;
        },
        toggleLand: (state) => {
            state.land = !state.land;
        },
        setActiveCurrency: (state, action) => {
            state.activeCurrency = action.payload;
        },
        setCurrencyPrice: (state, action) => {
            state.currencyPrice = action.payload;
        }
    }
});

export const { actions, reducer } = appSlice;