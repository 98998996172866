import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import Link from "components/basic/link";
import { Span } from "components/basic/text";
import configs from "configs";

interface HeaderProps {
    handleNotify: any,
    handleMenu: any,
    signed: boolean,
    handleAuthRouter: any
    userData: any
}

export default function HeaderUI({ handleNotify, handleMenu, signed, handleAuthRouter, userData }: HeaderProps) {

    return (
        <Flex $style={{
            vAlign: "center",
            hAlign: "space-between",
            p: "1rem",
            background: "#000000",
            borderBottom: "1px solid #1E1E24"
        }}>
            <Link to={configs.appConfigs.appPath.PUBLIC_PREFIX}>
                <Icon icon='logo' />
            </Link>
            <Flex $style={{
                gap: "1rem",
                vAlign: "center"
            }}>
                <Flex $style={{
                    gap: "1rem"
                }}>
                    <Flex onClick={() => handleAuthRouter(configs.appConfigs.appPath.SETTING_PREFIX)} $style={{
                        cursor: "pointer"
                    }}>
                        <Icon icon='setting' />
                    </Flex>
                    <Flex $style={{
                        cursor: "pointer",
                        position: "relative"
                    }}>
                        {
                            userData.newsStatus &&
                            <Flex $style={{
                                background: 'red',
                                w: ".4rem",
                                h: ".4rem",
                                position: "absolute",
                                radius: "20rem",
                                right: "4px",
                                top: "1px"
                            }}>

                            </Flex>

                        }
                        <Icon icon='bell' onClick={handleNotify} />
                    </Flex>
                </Flex>
                <Flex onClick={handleMenu} $style={{
                    display: "none",
                    queries: {
                        480: {
                            display: "block"
                        }
                    }
                }}>
                    <Icon icon='menu' />
                </Flex>
                {
                    signed ? <Link to={configs.appConfigs.appPath.PROFILE_PREFIX}>
                        <Flex $style={{
                            vAlign: "center",
                            gap: "1rem",
                            queries: {
                                480: {
                                    display: "none"
                                }
                            }
                        }}>
                            <Flex $style={{
                                maxW: "2.5rem",
                                radius: "10rem",
                                overflow: "hidden"
                            }}>
                                <Image src={`/profile/${userData?.image}.jpg`} />
                            </Flex>
                            <Flex $style={{
                                fDirection: "column"
                            }}>
                                <Span>{userData?.fname}</Span>
                                <Span>{userData?.job}</Span>
                            </Flex>
                        </Flex>
                    </Link> : <Flex $style={{
                        queries: {
                            480: {
                                display: "none"
                            }
                        }
                    }}>
                        <Link to={configs.appConfigs.appPath.SIGNIN_PREFIX}>
                            <Button $style={{
                                hover: "bg-2",
                                kind: "radius",
                                border: "1px solid #1DAEFF"
                            }}>
                                Login
                            </Button>
                        </Link>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}