const appPath = {
    PUBLIC_PREFIX: '/',
    APP_PREFIX: '/app/',
    SIGNIN_PREFIX: '/signin',
    SIGNUP_PREFIX: '/signup',
    FORGOT_PREFIX: '/forgot',
    CHANGE_PREFIX: '/change',
    PROPOSAL_PREFIX: 'proposal/',
    PASSPORT_PREFIX: 'passport',
    PAYMENT_PREFIX: 'payment',
    OVERVIEW_PREFIX: 'overview',
    PROFILE_PREFIX: 'profile',
    ADMIN_PREFIX: 'admin',
    PAY_PREFIX: 'pay',
    WITHDRAW_PREFIX: 'withdraw',
    CREATE_PREFIX: 'create',
    SETTING_PREFIX: 'setting'
}

export default appPath;